<template>
    <div>
        <v-progress-circular class="mt-12" size="60" width="12" color="primary" indeterminate v-if="loaded == false" />
        <v-btn color="success" class="ma-12" style="width: 75%" v-if="loaded == false" disabled>
            <div class="spinner hidden" id="spinner"></div>
            Preparando plataforma
        </v-btn>
        <v-row align="center" justify="center" v-else class="mt-2 mt-sm-0">
            <v-col cols="8" xl="2" lg="3" md="4" sm="4" v-if="qrCodeImage">
                <img :src="qrCodeImage" alt="QR Code" style="width: 100%; height: auto;" />
            </v-col>
            <v-col cols="12" v-if="pixUrl">
                <v-btn color="primary" class="mt-0 mb-12" style="width: 75%" @click="copyPixCode">
                    <v-icon left>mdi-content-copy</v-icon>
                    Copiar Pix Copia e Cola
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {

    props: {
        charger: {
            required: true,
        },
        chargerName: {
            required: true,
        },
        plugNumber: {
            required: true,
        },
        userId: {
            required: true,
        },
        price: {
            required: true,
        },
    },

    data() {
        return {
            loaded: false,
            PREFERENCE_ID: null,
            qrCodeImage: null,
            pixUrl: null,
            errorCount: 0,
            errorOnCreatePayment: undefined,
            timer: null
        };
    },

    mounted() {
        this.createPreference();
        this.timer = setInterval(this.verify, 5000);
    },

    methods: {
        verify() {
            if (this.errorOnCreatePayment == true) {
                this.errorOnCreatePayment = undefined;
                this.errorCount = 0;
                this.createPreference();
            }
        },
        error() {
            this.errorCount++;
            if (this.errorCount > 5) {
                this.errorOnCreatePayment = true;
                this.$eventHub.$emit("msgError", "Erro ao criar meio de pagamento.");
            } else {
                setTimeout(this.createPreference, 2000);
            }
        },
        createPreference() {
            this.$http
                .postDynamic(this.charger.server, "api/v2/payment/create", { operator: "mercadopagopix", chargerName: this.chargerName, plugNumber: this.plugNumber, userId: this.userId, price: this.price })
                .then((result) => {
                    if (result && result.id && result.qrcode_base64 && result.qrcode) {
                        this.PREFERENCE_ID = result.id;
                        this.qrCodeImage = `data:image/png;base64,${result.qrcode_base64}`;
                        this.pixUrl = result.qrcode;
                        this.loaded = true;
                    } else {
                        this.error();
                    }
                }).catch(this.error);

        },
        copyPixCode() {
            if (this.pixUrl) {
                navigator.clipboard.writeText(this.pixUrl).then(() => {
                    this.$eventHub.$emit("msgSuccess", "Copiado com sucesso, vá no aplicativo do seu banco e cole!");
                }).catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao copiar o código Pix.");
                });
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
}

</script>