<template>
    <div id="main">
        <v-container grid-list-xl>
            <v-layout wrap class="ma-2">
                <v-card class="mx-auto" style="width: 100%">
                    <Header :chargerName="chargerName" :charger="charger" class="mb-2 mb-sm-4" :hideDetails="hideDetails" />
                    <div v-if="charger == null">
                        <v-alert :icon="$vuetify.breakpoint.xs ? '' : 'error'" prominent text :type="$vuetify.breakpoint.xs ? '' : 'error'" color="error" border="top">
                            Não foi possivel localizar informação desse carregador.
                            <br>
                            Verifique com administrador do sistema se o mesmo está devidamente cadastrado no sistema.
                        </v-alert>
                    </div>
                    <div v-else>
                        <div v-if="charger.permissionToCharger == 'NO_USER' && (charger.polity == 'PUBLIC_LOCKED' || charger.polity == 'CONDOMINIUM')">
                            <v-alert :icon="$vuetify.breakpoint.xs ? '' : 'error'" prominent text :type="$vuetify.breakpoint.xs ? '' : 'error'" color="error" border="top">
                                <b>Você precisa estar logado no sistema.</b>
                                <br>
                                <br>
                                <RegisterOrLogin class="ml-2 mb-2" />
                            </v-alert>
                        </div>
                        <div v-else-if="charger.polity == 'CONDOMINIUM' && charger.permissionToCharger == 'FORBIDDEN'" class="mx-6 pb-4">
                            <v-alert :icon="$vuetify.breakpoint.xs ? '' : 'error'" prominent text :type="$vuetify.breakpoint.xs ? '' : 'error'" color="error" border="top">
                                <b>Você precisa de permissão para acessar esse carregador.</b>
                                <br>
                                Clique para solicitar o acesso e aguarde a aprovação do síndico.
                            </v-alert>
                            <router-link :to="{ path: `/resident/${charger.condominium}` }" tag="v-btn">
                                <v-btn block color="success" dark> <v-icon left> send </v-icon> Solicitar acesso </v-btn>
                            </router-link>
                        </div>
                        <div v-else>
                            <Plugs :chargerName="chargerName" :charger="charger" @changeDetailsMode="changeDetailsMode" />
                        </div>
                    </div>
                </v-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import Header from "./Header";
import Plugs from "./Plugs";
import RegisterOrLogin from "@/components/shared/RegisterOrLogin";

export default {
    components: { Plugs, Header, RegisterOrLogin },

    mounted() {
        if (this.$route.params.charger) {
            this.chargerName = this.$route.params.charger;
        }
        this.$eventHub.$on("makeLogin", this.update);
        this.update();
    },

    computed: {
        needLogin() {
            return true
        }
    },

    data() {
        return {
            chargerName: "",
            charger: null,
            hideDetails: false,
        };
    },

    methods: {
        changeDetailsMode(newValue) {
            this.hideDetails = newValue;
        },
        update() {
            this.$http
                .get("api/v2/charger-detail/" + this.chargerName)
                .then((result) => {
                    if (result)
                        this.charger = result;
                })
                .catch(() => {
                    this.charger = null;
                });
        },
    },
};
</script>

<style>
#main {
    background-color: var(--v-primary-base);
}
</style>