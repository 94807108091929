<template>
    <div>
        <form id="payment-form" v-show="loaded == true">
            <div id="payment-element">
            </div>

            <v-btn color="success" @click="tryPay" :disabled="sending">
                <div class="spinner hidden" id="spinner"></div>
                <v-icon dark left>check_circle</v-icon>
                Pagar
            </v-btn>

            <div id="payment-message" class="hidden"></div>
        </form>
        <v-progress-circular class="mt-12" size="60" width="12" color="primary" indeterminate v-if="loaded == false" />
        <v-btn color="success" class="ma-12" style="width: 75%" v-if="loaded == false" disabled>
            <div class="spinner hidden" id="spinner"></div>
            Preparando plataforma
        </v-btn>
    </div>
</template>

<script>

export default {

    props: {
        charger: {
            required: true,
        },
        chargerName: {
            required: true,
        },
        plugNumber: {
            required: true,
        },
        userId: {
            required: true,
        },
        price: {
            required: true,
        },
    },

    data() {
        return {
            PREFERENCE_ID: null,
            PUBLIC_KEY: null,
            loaded: false,
            stripe: null,
            elements: null,
            sending: false,
            errorCount: 0,
            errorOnCreatePayment: undefined,
            timer: null
        };
    },

    mounted() {
        this.createPreference();
        this.timer = setInterval(this.verify, 5000);
    },

    methods: {
        verify() {
            if (this.errorOnCreatePayment == true) {
                this.errorOnCreatePayment = undefined;
                this.errorCount = 0;
                this.createPreference();
            }
        },
        error() {
            this.errorCount++;
            if (this.errorCount > 5) {
                this.errorOnCreatePayment = true;
                this.$eventHub.$emit("msgError", "Erro ao criar meio de pagamento.");
            } else {
                setTimeout(this.createPreference, 2000);
            }
        },
        async tryPay() {
            this.sending = true;
            let elements = this.elements;
            const { error } = await this.stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/payment/${this.chargerName}/${this.plugNumber}/${this.userId}/${this.price}/stripe`,
                },
            });

            if (error.type === "card_error" || error.type === "validation_error") {
                this.$eventHub.$emit("msgError", error.message);
            } else {
                this.$eventHub.$emit("msgError", "Ocorreu um erro ao efetuar o pagamento.");
            }
            this.sending = false;
        },
        createPreference() {
            this.$http
                .postDynamic(this.charger.server, "api/v2/payment/create", { operator: "stripe", chargerName: this.chargerName, plugNumber: this.plugNumber, userId: this.userId, price: this.price })
                .then((result) => {
                    if (result && result.pk && result.id) {
                        this.errorCount = 0;
                        this.PREFERENCE_ID = result.id;
                        this.PUBLIC_KEY = result.pk;
                        this.start();
                    } else {
                        this.error();
                    }
                }).catch(this.error);

        },
        start() {
            try {
                this.stripe = window.Stripe(this.PUBLIC_KEY, { locale: "pt-BR" });

                const appearance = {
                    theme: 'stripe',
                };
                const clientSecret = this.PREFERENCE_ID

                this.elements = this.stripe.elements({ appearance, clientSecret });

                const paymentElementOptions = {
                    layout: {
                        type: 'accordion',
                        defaultCollapsed: true,
                    },
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'card'],
                };

                const paymentElement = this.elements.create("payment", paymentElementOptions);
                if (paymentElement) {
                    paymentElement.mount("#payment-element");
                    setTimeout(() => {
                        this.loaded = true;
                    }, 3000);
                }
            } catch {
                this.error();
            }
        },
    },
    
    beforeDestroy() {
        clearInterval(this.timer);
    },
}

</script>

<style scoped>
#payment-form {
    background-color: white;
}

* {
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
}

form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}

.hidden {
    display: none;
}

#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-element {
    margin-bottom: 24px;
}

/* Buttons and links */
button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

button:hover {
    filter: contrast(115%);
}

button:disabled {
    opacity: 0.5;
    cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: "";
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    form {
        width: 80vw;
        min-width: initial;
    }
}
</style>