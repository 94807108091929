const ChargerFeedback = Object.freeze({
    PAYMENT_WAIT: "PAYMENT_WAIT",
    PAYMENT_ERROR: "PAYMENT_ERROR",
    PAYMENT_OK: "PAYMENT_OK",
    WAIT_UNLOCK: "WAIT_UNLOCK",
    WAIT_START: "WAIT_START",
    WAIT_PLUG: "WAIT_PLUG",
    PROCCESS_OK: "PROCCESS_OK",
    ERROR: "ERROR",
});

module.exports = ChargerFeedback;