<template>
    <div>
        <div v-if="counter > 1">
            <v-tabs v-model="tab" :background-color="backgroundColor" color="primary" centered @change="changePaymentMethod">
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab key="1" href="#stripe" v-if="stripe">
                    Cartão
                </v-tab>
                <v-tab key="2" href="#mercadopago" v-if="mercadopago">
                    Cartão
                </v-tab>
                <v-tab key="3" href="#mercadopagopix" v-if="mercadopagopix">
                    PIX
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="text-center">
                <v-tab-item key="1" value="stripe">
                    <v-card flat :color="backgroundColor">
                        <v-row align="center" justify="center" align-content="space-between">
                            <ButtonStripe :charger="charger" :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="2" value="mercadopago">
                    <v-card flat :color="backgroundColor">
                        <v-row align="center" justify="center" align-content="space-between">
                            <ButtonMercadoPago :charger="charger" :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item key="3" value="mercadopagopix">
                    <v-card flat :color="backgroundColor">
                        <v-row align="center" justify="center" align-content="space-between">
                            <ButtonMercadoPagoPix :charger="charger" :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <div v-else-if="counter === 1">
            <v-row align="center" justify="center" align-content="space-between" v-if="mercadopago">
                <ButtonMercadoPago :charger="charger" :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
            </v-row>
            <v-row align="center" justify="center" align-content="space-between" v-if="stripe">
                <ButtonStripe :charger="charger" :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
            </v-row>
            <v-row align="center" justify="center" align-content="space-between" v-if="mercadopagopix">
                <ButtonMercadoPagoPix :charger="charger" :price="price" :plugNumber="plug" :chargerName="chargerName" :userId="userId" />
            </v-row>
        </div>
    </div>
</template>

<script>
import ButtonStripe from "@/components/shared/Payment/ButtonStripe";
import ButtonMercadoPago from "@/components/shared/Payment/ButtonMercadoPago";
import ButtonMercadoPagoPix from "@/components/shared/Payment/ButtonMercadoPagoPix";

export default {
    name: "PaymentOptions",

    components: {
        ButtonStripe,
        ButtonMercadoPago,
        ButtonMercadoPagoPix,
    },

    props: {
        charger: {
            required: true,
        },
        chargerName: {
            required: true,
        },
        price: {
            required: true,
        },
        plug: {
            required: true,
        },
        backgroundColor: {
            type: String,
            default: "warning",
        },
    },

    data() {
        return {
            tab: null,
            userId: null,
            mercadopago: false,
            mercadopagopix: false,
            stripe: false,
            preference: {
                method: null,
            },
        };
    },

    mounted() {
        this.getUser();
        this.getPayOption();
        this.$eventHub.$on("makeLogin", this.getUser);
    },

    created() {
        if (localStorage.getItem("payment_preference_v1")) {
            this.preference = JSON.parse(localStorage.getItem("payment_preference_v1"));
            this.tab = this.preference.method;
        }
    },

    computed: {
        counter() {
            return [this.mercadopago, this.stripe, this.mercadopagopix].filter(Boolean).length;
        }
    },

    methods: {
        changePaymentMethod() {
            if (this.tab) {
                this.preference.method = this.tab;
                localStorage.setItem("payment_preference_v1", JSON.stringify(this.preference));
            }
        },
        getUser() {
            if (this.$store.state.user.ID) {
                this.userId = this.$store.state.user.ID;
            } else {
                this.userId = "NO_USER";
            }
        },
        getPayOption() {
            this.$http
                .get("api/v2/payment-detail/" + this.chargerName)
                .then((result) => {
                    if (result) {
                        this.stripe = result.stripe;
                        this.mercadopago = result.mercadopago;
                        this.mercadopagopix = result.mercadopagopix;

                        if (this.preference.method && result[this.preference.method]) {
                            this.tab = this.preference.method;
                        }
                    }
                });
        }
    }
};
</script>